<template>
    <div class="order-details-page">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <HeadOrders
    :TitlePage="$route.name"
    :PathPageFrom="$t('Home')"
    :PathPageCenter="$t('Requested Services')"
    :PathPageTo="$t('Order_Detials')" />
    <div class="container">
    <Order_Details />
    </div>
  </div>
  </template>
  <script>
  import { defineAsyncComponent } from 'vue'
  export default {
  name: 'Services Order Details',
  components: {
    HeadOrders: defineAsyncComponent( () => import('@/components/Global/HeadOrders.vue') ),
    Order_Details: defineAsyncComponent( () => import('@/components/Services/Order/Order_Details.vue') ),
  },
  }
  </script>



